//@ts-nocheck
import { toast } from "react-toastify"
import useScenario from "./useScenario"
import useUrlParams from "./useUrlParams"
import { CreateScenarioType, SatelliteOrbit, SatelliteType, ScenarioResponseType } from "../types"
import { check_same_ground_stations, check_same_satellite, set_edited_scenario_data, validate_scenario_time, validate_simulation_time } from "../utils"
import SimulateService from "../../../../service/SimulateService"
import { useNavigate } from "react-router-dom"
import useUrlNavigation from "../../../command_center/hooks/useUrlNavigation"
import CommandCenterService from "../../../../service/CommandCenterService"
import useViewer from "./useViewer"
import { create_satellite_packet } from "../visualization/cesium_utils"


const useFormData = () => {
    const {
        duration,
        task_plan,
        satellite,
        set_loading,
        set_form_data,
        scenario_name,
        ground_stations,
        scenario_response,
        set_response_data,
        scenario_duration,
        scenario_start_time,
        simulation_start_time,
        additional_orbit_details,
    } = useScenario()
    const navigate = useNavigate()
    const { navigate_scenario_step, mission_name, mission_id, scenario_id, scenario_step } = useUrlParams()
    const { navigate_to_simulate } = useUrlNavigation()
    const { viewer } = useViewer()

    const validate_form_data = () => {
        let valid = true
        switch (scenario_step) {
            case 'details':
                if (!scenario_name) {
                    toast.error("Please enter scenario name.", { toastId: "scenario_name" })
                    return valid = false
                }
                break;
            case 'satellite':
                if (!satellite) {
                    toast.error("Please select a satellite.", { toastId: "satellite" })
                    return valid = false
                } else if (!validate_scenario_time(scenario_start_time)) {
                    return valid = false
                }
                break;
            case 'additional_object':
                if (!additional_orbit_details) {
                    navigate_scenario_step('forword')
                }
                break;
            case 'ground_station':
                if (!ground_stations || ground_stations.length === 0) {
                    toast.error("Please select ground station.", { toastId: "ground_station" })
                    return valid = false
                }
                break;
            case 'schedule':
                if (!validate_simulation_time(simulation_start_time, duration, scenario_start_time, scenario_duration)) return valid = false
                break;
            default:
                break;
        }
        return valid
    }

    const check_same_data = () => {
        switch (scenario_step) {
            case 'details':
                if (scenario_response && scenario_response.scenarioName === scenario_name) return true
                else if (scenario_name !== '') return false
                else return true
            case 'satellite':
                if (scenario_response && scenario_response?.satelliteGroups?.length > 0 && satellite) {
                    const previous_satellite = {
                        groupId: scenario_response.satelliteGroups[0].satelliteGroupId,
                        orbit_params: {
                            epoch: scenario_response.scenarioStartTime,
                            ...scenario_response.satelliteGroups[0].groupReferenceOrbit
                        }
                    }
                    const updated_satellite = {
                        groupId: satellite.value.groupId,
                        orbit_params: {
                            epoch: scenario_start_time,
                            ...satellite.value.orbit_data
                        }
                    }
                    return check_same_satellite(previous_satellite, updated_satellite) ? true : false
                } else if (satellite) return false
                else return true
            case 'ground_station':
                if (scenario_response && scenario_response?.groundStations.length > 0 && ground_stations.length > 0) {
                    const previous_ground_stations = scenario_response.groundStations
                    const updated_ground_stations = ground_stations
                    return check_same_ground_stations(previous_ground_stations, updated_ground_stations) ? true : false
                } else if (ground_stations.length > 0) return false
                else return true
            case 'schedule':
                if (task_plan && scenario_response && !scenario_response.taskPlanId) return false
                else if (task_plan && scenario_response && scenario_response.taskPlanId) {
                    if (scenario_response?.taskPlanId !== task_plan?.value.taskPlanId) return false
                    else return ((scenario_response.simulationStartTime === simulation_start_time) && (scenario_response.simulationDuration === duration)) ? true : false
                } else return (scenario_response && (scenario_response.simulationStartTime === simulation_start_time) && (scenario_response.simulationDuration === duration)) ? true : false
            default:
                break;
        }
    }

    const create_scenario = () => {
        if (!scenario_response && !scenario_id) {
            set_loading('Creating')
            const FormData: CreateScenarioType = {
                scenarioName: scenario_name,
                scenarioDeploymentType: 'SIMULATE',
                sampleRate: 1
            }
            SimulateService.createScenario(mission_id, FormData).then((res) => {
                if (res.data) {
                    const scenarioId = res.data.scenarioId
                    set_response_data('scenario_response', res.data)
                    navigate(`${process.env.PUBLIC_URL}/${mission_name}/${mission_id}/scenario/${scenarioId}/satellite`)
                }
            }).catch((err) => {
                console.log(err);
                toast.error('Error while creating scenario.', { toastId: "create_scenario" })
            }).finally(() => {
                set_loading(false)
            })
        } else {
            patch_scenario_name()
        }
    }

    const patch_scenario_name = () => {

    }

    const postGraphana = (satGroupData) => {
        SimulateService.postGrafanaData(scenario_id, mission_id, satGroupData?.satelliteGroups?.[0]?.satelliteGroupId)?.then((res) => {
            if (res?.data) {
                console.log("Graphana Post Successfully")
            }
        }).catch((error) => {
            toast.error("Somethiing went Wrong", { toastId: "error" })
        })
    }

    const patch_satellite = () => {
        set_loading('Plotting')
        const selected_satellite: any = satellite?.value
        const FormData: SatelliteType = {
            "epoch": scenario_start_time,
            "satelliteGroups": [
                {
                    "groupId": selected_satellite.groupId as string,
                    "referenceOrbit": selected_satellite.orbit_data as SatelliteOrbit
                }
            ]
        }
        SimulateService.updateScenarioWithSatelliteGroups(mission_id, scenario_id, FormData).then((res) => {
            if (res.data) {
                set_response_data('scenario_response', res.data)
                navigate_scenario_step('forword')
                postGraphana(res.data)
                create_satellite_packet(viewer, scenario_id, mission_id)
            }
        }).catch((err) => {
            console.log(err);
            toast.error('Error while uploading satellite.', { toastId: 'satellite' })
        }).finally(() => {
            set_loading(false)
        })
    }
    const patch_additional_orbit = () => {
        // additional_orbit_details
        console.log(additional_orbit_details);
        navigate_scenario_step('forword')
    }

    const patch_ground_station = () => {
        set_loading('Updating')
        const FormData: string[] = ground_stations
        SimulateService.updateScenarioWithGroundStations(mission_id, scenario_id, FormData).then((res) => {
            if (res.data) {
                set_response_data('scenario_response', res.data)
                navigate_scenario_step('forword')
            }
        }).catch((err) => {
            console.log(err);
            toast.error('Error while uploading ground station.', { toastId: 'ground_station' })
        }).finally(() => {
            set_loading(false)
        })
    }

    const get_schedule_data = (): Promise<{
        scenario_data: any; // Replace 'any' with specific scenario type if available
        schedule_data: any[];
        selected_schedule: any | null;
    }> => {
        const taskPlanId = task_plan ? task_plan?.value?.['taskPlanId'] : ''
        const FormData = `taskPlanId=${taskPlanId}&startEpoch=${simulation_start_time}&duration=${duration}`

        return new Promise((resolve, reject) => {
            SimulateService.generateSchedule(mission_id, scenario_id, FormData)
                .then(async (scheduleResponse: any) => {
                    const schedules = scheduleResponse.data
                        ? scheduleResponse.data.map((schedule: any) => ({
                            label: schedule?.['satName'],
                            value: schedule
                        }))
                        : [];

                    // Get scenario data after schedule generation succeeds
                    return SimulateService.getScenario(mission_id, scenario_id)
                        .then((scenarioResponse) => {
                            resolve({
                                scenario_data: scenarioResponse.data || null,
                                schedule_data: schedules,
                                selected_schedule: schedules[0] || null
                            });
                        });
                })
                .catch(reject);
        });
    }

    const get_generate_schedule = async () => {
        set_loading('Generating')
        try {
            const response = await get_schedule_data()
            if (response) {
                const simulation_time_data = set_edited_scenario_data('simulation_time', response.scenario_data)
                set_form_data('simulation_start_time', simulation_time_data ? simulation_time_data as number : simulation_start_time)
                set_response_data('scenario_data', response.scenario_data)
                set_response_data('schedule_data', response.schedule_data)
                set_response_data('selected_schedule', response.selected_schedule)
                navigate_scenario_step('forword')
            }

        } catch (error) {
            toast.error('Error generating schedule.', { toastId: 'error' })
        } finally {
            set_loading(false)
        }
    }

    const deploy_scenario = () => {
        set_loading("Deploying")
        SimulateService.deployScenario(mission_id, scenario_id)
            .then((result) => {
                if (result.data) {
                    // Handle successful deployment if needed
                    set_loading(false)
                    toast.success("Deployed Successfully", { toastId: "success" });
                    navigate_to_simulate()
                }
            })
            .catch((error) => {
                set_loading(false)
                toast.error("Error in Deploying Scenario", { toastId: 'error' });
            });
    };


    const submit_form = () => {
        if (!validate_form_data()) return
        if (check_same_data() && scenario_step !== 'schedule') {
            navigate_scenario_step('forword')
            return
        }
        switch (scenario_step) {
            case 'details':
                create_scenario()
                break;
            case 'satellite':
                patch_satellite()
                break;
            case 'additional_object':
                patch_additional_orbit()
                break;
            case 'ground_station':
                patch_ground_station()
                break;
            case 'schedule':
                get_generate_schedule()
                break;
            case 'deploy_scenario':
                deploy_scenario()
                break;
            default:
                break;
        }
    }

    return {
        submit_form,
        check_same_data
    }

}

export default useFormData