import { ButtonGroup, Dropdown } from 'react-bootstrap'
import CustomToolTip from '../CommonComponents/CustomToolTip'
import './SimulateDetails.scss'
import { SatImage, SimulateLogo, StatusSvg } from './svgs/Svgs'
import { get_formated_time } from './utils'
import { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import QuickDeployModal from './modals/quick_deploy/QuickDeployModal'
import DeployTypeModal from './modals/DeployTypeModal'
import { ScenarioResponseType } from './scenario/types'
import SimulateService from '../../service/SimulateService'
import { toast } from 'react-toastify'
import ConfirmationDialog from './modals/confirmation_dialog/ConfirmationDialog'
import CircularProgressbar from './circular_progress/CircularProgressbar'

const SimulateDetails = () => {
    const navigate = useNavigate()
    const { mission_name, mission_id } = useParams()
    const [scenarios, set_scenarios] = useState<ScenarioResponseType[]>([])
    const [open_deploy_type_modal, set_open_deploy_type_modal] = useState(false)
    const [open_quick_deploy_modal, set_open_quick_deploy_modal] = useState(false)
    const [open_confirmation_dialog, set_open_confirmation_dialog] = useState<boolean | any>(false)
    const [current_time, set_current_time] = useState<number>(new Date().getTime())

    const create_scenario = () => {
        navigate(`${process.env.PUBLIC_URL}/${mission_name}/${mission_id}/scenario/details`)
    }

    const update_scenario = (scenarioId: string) => {
        navigate(`${process.env.PUBLIC_URL}/${mission_name}/${mission_id}/scenario/${scenarioId}/details`)
    }
    const navigate_command_center = (scenario: ScenarioResponseType, dashboard: string) => {
        const scenarioId = scenario?.scenarioId
        const satelliteId = scenario?.satelliteGroups[0]?.satellites[0]?.satelliteId
        const satelliteGroupId = scenario?.satelliteGroups[0]?.satelliteGroupId
        // const trutwinId = scenario?.truetwinId
        const trutwinId = '4765e655'

        if (!scenarioId || !satelliteId) return
        navigate(`${process.env.PUBLIC_URL}/${mission_name}/${mission_id}/${dashboard}/${scenarioId}/satellite/${satelliteId}/truetwin/${trutwinId}/satGroupId/${satelliteGroupId}/hud`)
    };

    const get_scenarios = async () => {
        if (!mission_id) return
        try {
            const response = await SimulateService.getALLScenarios(mission_id)
            if (response.data) {
                set_scenarios(response.data)
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message || 'Something went wrong', { toastId: 'get_scenarios' })
        }
    }

    const delete_scenario = async (scenarioId: string) => {
        try {
            const response = await SimulateService.deleteScenario(mission_id, scenarioId)
            if (response.data) {
                const filter_scenarios = scenarios.filter((scenario: ScenarioResponseType) => scenario.scenarioId !== scenarioId)
                set_scenarios(filter_scenarios)
                toast.success('Scenario deleted successfully', { toastId: 'delete_scenario' })
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message || 'Something went wrong', { toastId: 'delete_scenario' })
        }
    }

    useEffect(() => {
        get_scenarios()
    }, [mission_id])

    const select_deplooy_type = (type: string) => {
        switch (type) {
            case 'scenario':
                create_scenario()
                break
            case 'quick_deploy':
                set_open_deploy_type_modal(false)
                set_open_quick_deploy_modal(true)
                break
            default:
                break
        }
    }

    const get_current_time = (status: string, simulation_start_time: number, simulation_end_time: number) => {
        let current_time = 0
        if (status === 'running') {
            current_time = new Date().getTime()
            current_time = simulation_start_time
        }
    }

    useEffect(() => {
        if (!scenarios.length) return
        const running_scenario = scenarios.find((scenario: ScenarioResponseType) => scenario.status === 'running')
        if (!running_scenario) return
        const simulation_start_time = running_scenario.simulationStartTime * 1000
        const simulation_duration = running_scenario.simulationDuration * 1000
        const simulation_end_time = simulation_start_time + simulation_duration
        const Interval = setInterval(() => {
            const current_time = new Date().getTime()
            if (current_time > simulation_end_time) {
                clearInterval(Interval)
                set_current_time(simulation_end_time)
                get_scenarios()
            } else {
                set_current_time(current_time)
            }
        }, 1000)
        return () => clearInterval(Interval)
    }, [scenarios])

    return (
        <Fragment>
            <div className='simulate__details__page'>
                <div className='simulate__page__header'>
                    <div className='d-flex gap-2 align-items-center'>
                        <SimulateLogo />
                        <span className='simulate__header__title'>Scenarios</span>
                    </div>
                    <button className='simulate__header__deploy__button' onClick={() => set_open_deploy_type_modal(true)}>
                        <i className='fe fe-plus pe-1' />
                        Deploy
                    </button>
                </div>
                <div className='simulate__page__body'>
                    <div className='simulate__body__table__header'>
                        <div className='w-15 d-flex justify-content-start'>SCENARIOS</div>
                        <div className='w-15 d-flex justify-content-start'>SATELLITES</div>
                        <div className='w-15 d-flex justify-content-center'>STATUS</div>
                        <div className='w-20 d-flex justify-content-center'>START TIME</div>
                        <div className='w-20 d-flex justify-content-center'>CREATION TIME</div>
                        <div className='w-10 d-flex justify-content-center'>DURATION</div>
                        <div className='w-5 ' />
                    </div>
                    <div className='simulate__body__table__container'>
                        {scenarios && scenarios.map((scenario: ScenarioResponseType, index) => {
                            const status = scenario.draftMode === 'Yes' ? 'draft' : scenario.status === 'Run Simulation' ? 'ready_to_deploy' : scenario.status === "SimulationRunning" ? 'running' : scenario.status === "SimulationCompleted" ? "completed" : scenario.status
                            const simulation_start_time = scenario.simulationStartTime * 1000
                            const simulation_duration = scenario.simulationDuration * 1000
                            const simulation_end_time = simulation_start_time + simulation_duration

                            return (
                                <div className={`simulate__body__table__row ${status}`} key={index}>
                                    <div className='w-15 text-truncate'>
                                        <CustomToolTip title={scenario.scenarioName} placement='top-start'>
                                            {scenario.scenarioName}
                                        </CustomToolTip>
                                    </div>
                                    <div className='w-15 d-flex justify-content-start gap-2'>
                                        <SatImage type={scenario?.satelliteGroups[0]?.satelliteGroupType} />
                                        <span className='w-95 text-truncate'>
                                            <CustomToolTip title={scenario?.satelliteGroups[0]?.satelliteGroupName || ''} placement='top-start'>
                                                {scenario.satelliteGroups[0]?.satelliteGroupName || '-'}
                                            </CustomToolTip>
                                        </span>
                                    </div>
                                    <div className='w-15 d-flex justify-content-center'>
                                        <div className='status__badge'>
                                            <div className='status__indicator' />
                                            {status === 'ready_to_deploy' ? 'Ready to Deploy' : status === "ReadyForSimulation" ? "Ready For Simulation" : status === "DeploymentInProgress" ? "Deployment In Progress" : status}
                                        </div>
                                    </div>
                                    <div className='w-20 opacity-50 d-flex justify-content-center'>
                                        {scenario?.simulationStartTime ? get_formated_time(scenario?.simulationStartTime).split('Z')[0] + ' UTC' : '-'}
                                    </div>
                                    <div className='w-20 opacity-50 d-flex justify-content-center'>
                                        {scenario?.createdAt ? scenario?.createdAt.split('Z')[0].split('.')[0] + ' UTC' : '-'}
                                    </div>
                                    <div className='w-10 opacity-50 d-flex justify-content-center'>
                                        {scenario.simulationDuration} Min
                                    </div>
                                    <div className='w-5 d-flex justify-content-end align-items-center gap-2'>
                                        <button className={`simulate__body__table__row__button`}
                                            onClick={() => {
                                                if (status === 'running') {
                                                    navigate_command_center(scenario, "simulation")
                                                }
                                                if (status === 'completed') {
                                                    navigate_command_center(scenario, "summary")
                                                }
                                                if (status === 'ready_to_deploy' || status === "DeploymentInProgress" || status === "ReadyForSimulation") {
                                                    navigate(`${process.env.PUBLIC_URL}/${mission_name}/${mission_id}/scenario/${scenario?.uniqueId}/deploy_scenario`)
                                                }
                                            }}
                                        >
                                            {status === 'running' ?
                                                <CircularProgressbar
                                                    simulation_start_time={simulation_start_time}
                                                    simulation_end_time={simulation_end_time}
                                                    simulation_current_time={current_time}
                                                /> : <StatusSvg status={status} />
                                            }
                                        </button>
                                        <div className='color-table-header-text'>
                                            <div className='simulate__body__table__row__button'>
                                                <ButtonGroup size="sm" className="flex-nowrap mb-0">
                                                    <div className="btn-list more-button">
                                                        <Dropdown drop="end">
                                                            <Dropdown.Toggle
                                                                size="sm"
                                                                variant="transparent"
                                                                disabled={false}
                                                                className='pr-0'
                                                            >
                                                                <i className="fe fe-more-vertical fs-20"></i>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className='button__dropdown__container'>
                                                                <Dropdown.Item eventKey="0"
                                                                    className='button__dropdown__item'
                                                                    disabled={status === "running" || status === "completed" || status === "deploying" || status === "DeploymentInProgress"}
                                                                    onClick={() => {
                                                                        update_scenario(scenario?.scenarioId)
                                                                    }}
                                                                >
                                                                    Edit
                                                                </Dropdown.Item>
                                                                {/* <Dropdown.Item eventKey="1"
                                                            className='button__dropdown__item'
                                                            disabled={false}
                                                            onClick={() => {
                                                            }}
                                                        >
                                                            Clone
                                                        </Dropdown.Item> */}
                                                                <Dropdown.Item eventKey="2"
                                                                    className='button__dropdown__item'
                                                                    disabled={false}
                                                                    onClick={() => {
                                                                        set_open_confirmation_dialog({
                                                                            name: scenario?.scenarioName,
                                                                            scenarioId: scenario?.scenarioId
                                                                        })
                                                                    }}
                                                                >
                                                                    Delete
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <DeployTypeModal
                open={open_deploy_type_modal}
                close={() => set_open_deploy_type_modal(false)}
                select_deplooy_type={(type: string) => {
                    select_deplooy_type(type)
                }}
            />
            <QuickDeployModal
                open={open_quick_deploy_modal}
                close={() => set_open_quick_deploy_modal(false)}
            />
            <ConfirmationDialog
                open={open_confirmation_dialog ? true : false}
                type={'delete'}
                title="Delete Scenario"
                description={`Deleting ${open_confirmation_dialog.name} will permanently remove it from the database, and it cannot be restored once deleted.`}
                onConfirm={() => {
                    delete_scenario(open_confirmation_dialog.scenarioId as string)
                    set_open_confirmation_dialog(false)
                }}
                onCancel={() => { set_open_confirmation_dialog(false) }}
                confirm_button_text="Delete"
            />

        </Fragment>
    )
}

export default SimulateDetails