// @ts-nocheck
import { Button, Col, Form } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setEdge, MissionStore, setIsEdgeEdit, setEditEdgeData, setEdgeSelected } from '../../../../../store/MissionSlice';
import CatalogueService from '../../../../../service/CatalogueService';
import { useNavigate, useParams } from 'react-router-dom';
import { calculateEdgeUsage, calculatePayloadUsage, calculate_Total_AOP_Usage, navigateTo, truncateNumber, uuidv4 } from '../../../../CommonComponents/CommonFunctions';
import { toast } from 'react-toastify';
import MissionService from '../../../../../service/MissionService';

const EdgeSlider = ({ satEdges, setSatEdges, satellieteDeta, setSelectedEdgeId, allEdges, allPayloads }) => {
    const edges = useSelector((state: { mission: MissionStore }) => state.mission.Edges);
    const [edgeId, setedgeId] = useState<any>();
    const [selectedSSDId, setSelectedSSDId] = useState<number>(0);
    const [totalSSDCount, setTotalSSDCount] = useState<number>(0);
    const [timePerOrbit, setTimePerOrbit] = useState<any>(5);
    const [ssd, setSSD] = useState({});
    const [EdgeSSDList, setEdgeSSDList] = useState<any[]>([]);
    const EditEdgeData = useSelector((state: { mission: MissionStore }) => state.mission.EditEdgeData);
    const isEditEdgeData = useSelector((state: { mission: MissionStore }) => state.mission.isEditEdgeData);
    const [selectedEdge, setSelectedEdge] = useState([])

    const edgePowerUsage = useSelector(
        (state: { usage: usageStore }) => state.usage.edgePowerUsage
    );
    const edgeSizeUsage = useSelector(
        (state: { usage: usageStore }) => state.usage.edgeSizeUsage
    );
    const edgeWeightUsage = useSelector(
        (state: { usage: usageStore }) => state.usage.edgeWeightUsage
    );
    const totalEdgeAOPUsage = useSelector(
        (state: { usage: usageStore }) => state.usage.totalEdgeAOPUsage
    );

    const params = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { mission_name, mission_id, sat_name, sat_id, edge_id } = params

    useEffect(() => {
        const response = CatalogueService.getCatalogue('Edge', 'Edge SSD')
        response?.then((data) => {
            if (data.data) {
                setEdgeSSDList(data.data)
            }
        }).catch((err) => {
            console.log(err)
        })
    }, []);

    const reset_set_data = () => {
        dispatch(setEdge({}));
        dispatch(setEdgeSelected(false));
        setSSD({})
        setTotalSSDCount(0)
        if (isEditEdgeData) {
            dispatch(setIsEdgeEdit(false));
            dispatch(setEditEdgeData({}));
        }
    }
    const update_satellite = (select_edge: any) => {

        let satellite = {
            tenantId: '',
            name: select_edge?.[0]?.['edge-device']?.['atmos-data']?.['name'],
            satelliteGroupId: sat_id,
            "edge-device": {
                'atmos-data': select_edge?.[0]?.['edge-device']?.['atmos-data'],
                'user-data': select_edge?.[0]?.['edge-device']?.['user_data'],
                catalogueId: select_edge?.[0]?.['edge-device']?.['atmos-data']?.['uniqueId']
            },
            'edge-ssd': {
                'atmos-data': select_edge?.[0]?.['edge-ssd']?.['atmos-data'],
                'user-data': select_edge?.[0]?.['edge-ssd']?.['user_data'],
                catalogueId: select_edge?.[0]?.['edge-device']?.['atmos-data']?.['uniqueId']
            }
        }

        const sat = {
            ...satellieteDeta,
            swapInfo: {
                ...satellieteDeta?.swapInfo,
                totalEdgeMass: edgeWeightUsage,
                totalEdgePower: edgePowerUsage,
                totalEdgeSize: edgeSizeUsage,
                totalEdgeAop: totalEdgeAOPUsage,
            }
        }

        MissionService.edit_satellite(mission_id, sat_id, sat)?.then((res) => {
            if (res?.data) {
                if (isEditEdgeData) {
                    MissionService.delete_edge(mission_id, sat_id, edge_id)?.then((res) => {
                        if (res?.data) {
                            MissionService.add_edge(sat_id, mission_id, satellite)?.then((res) => {
                                if (res.data) {
                                    toast.success(`Edge ${isEditEdgeData ? 'updated' : 'added'} successfully`, { toastId: 'o' });
                                    reset_set_data()
                                    navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/preview_edge`, navigate)
                                }
                            })
                                .catch((err) => {
                                    toast.error("Something went wrong while adding edge ", { toastId: 'o' });
                                });
                        }
                    }).catch((e) => {
                        toast.error("Something went wrong while deleting edge", { toastId: 'o' })
                    })
                } else {
                    MissionService.add_edge(sat_id, mission_id, satellite)?.then((res) => {
                        if (res.data) {
                            toast.success(`Edge ${isEditEdgeData ? 'updated' : 'added'} successfully`, { toastId: 'o' });
                            reset_set_data()
                            navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/preview_edge`, navigate)
                        }
                    })
                        .catch((err) => {
                            toast.error("Something went wrong while adding edge ", { toastId: 'o' });
                        });
                }
            }
            
        }).catch((e) => {
            toast.error("Something went wrong while adding swapInfo", { toastId })
        })


    }

    const onSelectSSD = () => {
        let all_payloads = allPayloads
        let all_edges = allEdges
        if (totalSSDCount > 0) {
            let dutyCycle = truncateNumber(((timePerOrbit === '' ? 5 : timePerOrbit) / 90), 2);  // ------> dutycycle = timeperOrbit/90view_satelliteview_satellite
            let total_aop = truncateNumber(((edges?.["max_power_consumption_in_watts"] * dutyCycle) + ((Number(ssd?.["max_power_consumption_in_watts"]) * totalSSDCount)) * dutyCycle), 2);  // -----> aop = maxPower * dutyCycle
            let edge_aop = truncateNumber((edges?.["max_power_consumption_in_watts"] * dutyCycle), 2);  // -----> aop = maxPower * dutyCycle
            let ssd_aop = truncateNumber(((ssd?.["max_power_consumption_in_watts"] * totalSSDCount) * dutyCycle), 2);  // -----> aop = maxPower * dutyCycle
            let peakSolarPower = truncateNumber((total_aop / 0.8 * 2 * 2), 2); //----->PeakSolarPower = aop * efficiency(80%) * eclipse Offset(2) * 2

            let neEdges: any = {
                'atmos-data': edges,
                'user_data': {
                    'dutyCycle': dutyCycle * 100,
                    'aop': edge_aop,
                    'total_aop': total_aop,
                    'peak_solar_power': peakSolarPower,
                    'time_per_orbit': timePerOrbit === '' ? 5 : +timePerOrbit,
                    "numUnits": 1,
                },
                sequences: [],
            }
            let newSSD = {
                'atmos-data': ssd,
                'user_data': {
                    'aop': ssd_aop,
                    'time_per_orbit': timePerOrbit === '' ? 5 : +timePerOrbit,
                    'numUnits': +totalSSDCount
                },
                sequences: [],
            }

            if (!isEditEdgeData) {
                all_edges = [...all_edges, { 'edge-id': uuidv4(), 'edge-device': neEdges, 'edge-ssd': newSSD }];
                setSelectedEdge([{ 'edge-id': uuidv4(), 'edge-device': neEdges, 'edge-ssd': newSSD }])
                setSatEdges(all_edges)
                calculateEdgeUsage(all_edges, dispatch)
                calculate_Total_AOP_Usage(all_payloads, all_edges, dispatch)
            } if (isEditEdgeData) {
                const filterData = all_edges?.filter((v) => v?.['edge-id'] !== EditEdgeData?.['edge-id']);
                const updatedData = [...filterData, {
                    'edge-id': EditEdgeData?.['edge-id'],
                    'edge-ssd': newSSD,
                    'edge-device': neEdges,
                }]
                setSatEdges(updatedData)
                setSelectedEdge([{
                    'edge-id': EditEdgeData?.['edge-id'],
                    'edge-ssd': newSSD,
                    'edge-device': neEdges,
                }])

                calculateEdgeUsage(updatedData, dispatch)
                calculate_Total_AOP_Usage(all_payloads, updatedData, dispatch)
            }
        } else {
            setSatEdges(all_edges)
            calculateEdgeUsage(all_edges, dispatch);
            calculate_Total_AOP_Usage(all_payloads, all_edges, dispatch);
        }
    };

    useEffect(() => {
        if (Number(totalSSDCount) >= 0 && ssd && Number(timePerOrbit) >= 0) {
            onSelectSSD()
        }
    }, [totalSSDCount, ssd, timePerOrbit])


    const onAddEdge = () => {
        // update_satellite(satEdges)
        update_satellite(selectedEdge)
    };

    const close_slider = () => {
        let all_payloads = allPayloads;
        let all_edges = allEdges;
        calculateEdgeUsage(all_edges, dispatch); //calculate the edge swap info after closing the ssd slider
        calculate_Total_AOP_Usage(all_payloads, all_edges, dispatch); //calculate total power usage (swap info)
        setSSD({})
        setSatEdges(all_edges)
        setSelectedEdgeId('')
        setTotalSSDCount(0)
        dispatch(setEdge({}))
        dispatch(setEdgeSelected(false));
    }

    useEffect(() => {
        if (isEditEdgeData) {
            if (EditEdgeData) {
                setTotalSSDCount(EditEdgeData?.['edge-ssd']?.['user_data'] ? EditEdgeData?.['edge-ssd']?.['user_data']?.['numUnits'] : EditEdgeData?.['edge-ssd']?.['user-data']?.['numUnits'])
                setSelectedSSDId(EditEdgeData?.['edge-ssd']?.['atmos-data']?.['uniqueId']);
                setSSD(EditEdgeData?.['edge-ssd']?.['atmos-data']);
                setTimePerOrbit(EditEdgeData?.['edge-ssd']?.['user_data'] ? EditEdgeData?.['edge-device']?.['user_data']?.['time_per_orbit'] : EditEdgeData?.['edge-device']?.['user-data']?.['time_per_orbit'])
                setedgeId(EditEdgeData?.['edge-id'])
            }
        }
    }, [isEditEdgeData]);


    return <div className="right-slider-box">
        <div className="slider-header">
            <h4>Edge Configuration</h4>
        </div>
        <div className="slider-content" style={{ height: '80vh' }}>
            <Form
                className="w-100"
            >
                <div className="form-row ">
                    <Col className="mb-5">
                        <Form.Label>Time Per Orbit (min)</Form.Label>
                        <Form.Control
                            required
                            type="number"
                            placeholder="Enter time per orbit (min)"
                            value={timePerOrbit}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13 || e.keyCode === 69) {
                                    e.preventDefault();
                                }
                            }}
                            onChange={(event) => {
                                event.preventDefault();
                                if (event.target.value >= 0 && event.target.value.length <= 5) {
                                    setTimePerOrbit(event.target.value)
                                }
                            }}>
                        </Form.Control>
                    </Col>
                </div>
            </Form>

            <strong className='fs-18 d-flex mb-2'>Select SSD</strong>


            {EdgeSSDList?.length > 0 && EdgeSSDList?.map((data, i) => {
                return <div className={data?.['uniqueId'] === selectedSSDId ? 'common-card-box  active w-100' : 'common-card-box w-100'}
                    key={i} onClick={() => {
                        setSSD(data);
                        setSelectedSSDId(data['uniqueId']);
                        setTotalSSDCount(1);
                    }}>
                    <div className="common-card-header">
                        {data?.['name']}
                    </div>
                    <div className="common-card-content">
                        <div className="d-flex flex-wrap">
                            <div className="stats-alpha">
                                <strong>{data?.['product-cookie']?.['Capacity']}</strong>
                                <span>Capacity</span>
                            </div>
                            <div className="stats-alpha">
                                <strong>{data?.['max_power_consumption_in_watts'] + ' W'}</strong>
                                <span>Peak Power</span>
                            </div>
                        </div>
                    </div>
                </div>;
            })}

            <div className='d-flex w-100 align-items-center justify-content-between mb-5'>
                <strong className='fs-18'>SSD Count</strong>
                <div className='d-flex align-items-center'>
                    <Button
                        variant="primary"
                        type="button"
                        disabled={selectedSSDId === 0 && totalSSDCount === 0}
                        className="btn btn-primary counter-btn me-3"
                        onClick={() => setTotalSSDCount((prevState) => {
                            if (prevState > 0 && totalSSDCount > 0) {
                                if (totalSSDCount === 1) {
                                    setSelectedSSDId(0)
                                }
                                return prevState - 1;
                            } else {
                                setSelectedSSDId(0)
                                return 0;
                            }
                        })}
                    >
                        <i className="fe fe-minus"></i>
                    </Button>

                    {totalSSDCount}

                    <Button
                        variant="primary"
                        type="button"
                        disabled={selectedSSDId === 0 || totalSSDCount === 3}
                        className="btn btn-primary counter-btn ms-3"
                        onClick={() => setTotalSSDCount((prevState) => prevState + 1)}
                    >
                        <i className="fe fe-plus"></i>
                    </Button>

                </div>
            </div>

        </div>

        <div className="slider-footer justify-content-between w-100 d-flex mt-0">
            <Button
                variant="primary-outline"
                type="button"
                className="btn btn-outline-danger pt-1 pb-1 pe-6 ps-6 me-3 fs-14"
                onClick={close_slider}
            >
                Close
            </Button>
            <Button
                variant="primary"
                type="button"
                disabled={selectedSSDId === 0}
                className="btn btn-primary pt-1 pb-1 pe-6 ps-6 fs-14"
                onClick={() => {
                    if (timePerOrbit === '' || +timePerOrbit === 0) {
                        toast.error("Time per orbit can't be empty or 0 .", { toastId: 'o' });
                        return
                    }
                    else {
                        onAddEdge()
                    }
                }}
            >
                Save & Continue
            </Button>

        </div>
    </div>;
};
export default EdgeSlider;
