import { useNavigate, useParams } from 'react-router-dom';
import './missionConfigNew.css'
import Loader from '../../Layouts/Loader/Loader';
import { useState } from 'react';
import { navigateTo } from '../CommonComponents/CommonFunctions';
import MissionService from '../../service/MissionService';
import { toast } from 'react-toastify';
import { MissionStore, setMissionEdit, setMission } from '../../store/MissionSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import Dialog from '../CommonComponents/Dialog';
import { containsSpecialChars } from '../CommonComponents/CommonFunctions';

const MissionConfigNew = () => {
    const params = useParams()
    const { project_id, mission_name, mission_id } = params
    const MISSION_OBJECTIVE = ['Earth Observation', 'Communication', 'Other']
    const missionEdit = useSelector(
        (state: { mission: MissionStore }) => state.mission.missionEdit
    );
    const [missionIdEditCase, setMissionIdEditCase] = useState("")
    const dispatch = useDispatch();
    const [projectIdEditCase, setProjectIdEditCase] = useState("")
    const [isShowModal, setIsShowModal] = useState(false)

    const [loading, setLoading] = useState(false)
    let navigate = useNavigate();

    const [missionData, setMissionData] = useState({
        title: '',
        summary: '',
        objective: 'Earth Observation',
        life: ''
    })

    const get_mission = () => {
        setLoading(true)
        MissionService.get_mission_by_id(mission_id)?.then((res) => {
            if (res.data) {
                setMissionIdEditCase(res.data?.uniqueId)
                setProjectIdEditCase(res?.data?.projectId)
                dispatch(setMission(res.data))
                dispatch(setMissionEdit(true))
                setMissionData({
                    title: res?.data?.missionTitle,
                    summary: res?.data?.summary,
                    objective: res?.data?.missionObjective,
                    life: res?.data?.missionLife
                })
            }
            else {
                navigateTo(`${missionData.title}/_details/${mission_id}/${'Satellites'}`, navigate)
                toast.error("Something went wrong while updating mission", { toastId: 'o' })
            }
            setLoading(false)
        }).catch((err) => {
            console.error(err);
            navigate(-1);
            setLoading(false)
            toast.error("Something went wrong", { toastId: 'o' })
        })
    }

    const validateMissionInfo = (data) => {
        if (!data?.missionTitle || data?.missionTitle.trim() === "") {
            toast.error("Please enter a valid mission title", { toastId: 'o' });
            return false;
        } else if (data?.missionLife === "" || !data?.missionLife) {
            toast.error("Please enter the mission life", { toastId: 'o' })
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (mission_id) {
            get_mission()
        }
    }, [mission_id])

    const createMission = () => {
        const data = {
            "missionLife": parseFloat(missionData.life),
            "missionTitle": missionData.title,
            "missionObjective": missionData.objective,
            "projectId": missionEdit ? projectIdEditCase : project_id,
            "summary": missionData.summary
        }
        const valid = validateMissionInfo(data);
        if (valid) {
            if (missionEdit) {
                MissionService.updateMission(mission_id, data)?.then((res) => {
                    if (res) {
                        dispatch(setMission({ data }))
                        dispatch(setMissionEdit(false))
                        toast.success('Mission updated successfully', { toastId: 'o' });
                        navigateTo(`${missionData.title}/_details/${missionIdEditCase}/${'Satellites'}`, navigate)
                    }
                }).catch(err => {
                    console.error(err)
                    toast.error('Something went wrong while updating mission', { toastId: 'o' })
                })
            } else {
                MissionService.createMission(data)?.then((res) => {
                    if (res) {
                        const missionId = res?.data?.missionId
                        toast.success('Mission created successfully', { toastId: 'o' });
                        navigateTo(`${missionData.title}/_details/${missionId}/${'Satellites'}`, navigate) // Navigations to Mission Screen After API Success
                    }
                }).catch(err => {
                    console.error(err)
                    if (err === 'Mission Already exists') {
                        toast.error(err, { toastId: 'o' })
                    } else {
                        toast.error("Something went wrong while creating mission", { toastId: 'o' })
                    }
                })
            }
        }
    }

    return (
        <>
            <div className='create_mission_container'>
                {loading && <Loader />}
                <div className='mission_heading'>
                    New Mission
                </div>
                <div className="separator" />
                <div className='horizontal_container'>
                    <div className='field_container'>
                        <span className=''> Mission Title * </span>
                        <input
                            className='input_field'
                            value={missionData.title}
                            onChange={(e) => {
                                e.preventDefault();
                                let isNotValidName = containsSpecialChars(e.target.value)
                                if (isNotValidName) {
                                    toast.dismiss()
                                    toast.error('Mission title cannot contain special characters', { toastId: 'o' })
                                } else {
                                    if (e.target.value.length > 26) {
                                        toast.dismiss()
                                        toast.error('Mission title cannot exceed 26 characters', { toastId: 'o' })
                                    } else {
                                        setMissionData({
                                            ...missionData,
                                            title: e.target.value
                                        })
                                    }
                                }
                            }}
                            placeholder='Title'
                        />
                    </div>
                    <div className='field_container'>
                        <span> Summary </span>
                        <input
                            className='input_field'
                            value={missionData.summary}
                            placeholder='Summary'
                            onChange={(e) => {
                                setMissionData({
                                    ...missionData,
                                    summary: e.target.value
                                })
                            }}
                        />
                    </div>
                </div>
                <div className='horizontal_container'>
                    <div className='field_container'>
                        <span> Mission Objective * </span>
                        <div className='mission_obj_items'>
                            {
                                MISSION_OBJECTIVE?.map((item, index) => (
                                    <div
                                        className={`mission_obj_item mo_font ${missionData?.objective === item ? 'selected_mo' : ''}`} key={index}
                                        onClick={(e: any) => {
                                            e.preventDefault();
                                            setMissionData({
                                                ...missionData,
                                                objective: e.target.innerText
                                            })
                                        }}
                                    >
                                        {item}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className='field_container'>
                        <span> Mission Life * </span>
                        <input
                            className='input_field'
                            type='text'
                            value={missionData.life}
                            onChange={(e) => {
                                const input = e.target.value;
                                if (/^\d*$/.test(input)) {
                                    setMissionData({
                                        ...missionData,
                                        life: input
                                    });
                                } else {
                                    toast.error("Mission life can only contain numbers", { toastId: 'o' });
                                }
                            }}
                            placeholder='# Years'
                        />
                    </div>
                </div>
                <div className="separator" />

                <div className='submission_buttons'>
                    <div className='mission_obj_item submit_font cancle_button'
                        onClick={() => {
                            setIsShowModal(true)

                        }}
                    >
                        Cancel
                    </div>
                    <div
                        className='mission_obj_item submit_font submit_button'
                        onClick={() => {
                            createMission()
                        }}
                    >
                        {missionEdit ? 'Update Mission' : 'Create Mission'}
                    </div>
                </div>

            </div>
            <Dialog
                onClick={(event) => {
                    if (event) {
                        if (missionEdit) {
                            dispatch(setMissionEdit(false))
                            navigateTo(`${missionData.title}/_details/${missionIdEditCase}/${'Satellites'}`, navigate) // Navigations to Mission Screen After API Success
                        } else {
                            dispatch(setMissionEdit(false))
                            navigateTo(`super_admin_dashboard`, navigate)
                        }
                    }
                    setIsShowModal(false);
                }}
                openModel={isShowModal}
                comment="Are you sure you want to discard the changes?"
            />
        </>

    )
};

export default MissionConfigNew