import { useEffect, useState } from "react"
import useScenario from "./useScenario"
import { toast } from "react-toastify"
import CommandCenterService from "../../../service/CommandCenterService"
import useTrueTwin from "./useTrueTwin"
import useUrlParams from "./useUrlParams"
import useSatellite from "./useSatellite"

const useMonitor = () => {
    const { loading, scenario, grafana } = useScenario()
    const { selected_satellite } = useSatellite()
    // const { truetwin_id, scenario_id } = useUrlParams()
    const [selected_panel, set_selected_panel] = useState<string>('EPS')
    const [monitor_view_type, set_monitor_view_type] = useState<string>('Grid')
    const [grafana_data, set_grafana_data] = useState<any>({})
    const [panels, set_panels] = useState<any | null>(null)


    useEffect(() => {
        if (grafana) {
            const grafana_data = grafana?.[0]
            set_grafana_data(grafana_data)
            set_panels(grafana_data?.[selected_panel === "Communication" ? 'Comms' : selected_panel])
            set_selected_panel(selected_panel)
            change_monitor_view_type('Grid')
        }
    }, [scenario, selected_satellite])
    const [fileName, setFileName] = useState('');


    const handleFileUpload = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = '.txt'; // Accept only .txt files
        fileInput.onchange = (event: any) => {
            const file = event.target.files[0];
            if (file && file.type === 'text/plain') {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    if (!e) return;
                    const uploadFile = e.target.result;
                    const blob = new Blob([uploadFile], { type: "multipart/form-data" });
                    const file_data = new FormData();
                    file_data.append("file", blob, file.name);
                    // Call the upload API with the FormData object
                    setFileName(file.name);
                    uploadAPI(file_data);
                };
                reader.readAsText(file);
            } else {
                console.error('Invalid file format');
                toast.error('Invalid file format. Please upload a .txt file.', { toastId: '0' });
            }
        };
        fileInput.click();
    };

    const uploadAPI = (file_data) => {
        // CommandCenterService.postUploadFileConsoleFlatSatTele(scenario_id, truetwin_id, file_data)
        //     .then((response) => {
        //         if (response) {
        //             setFileName('')

        //             toast.success('File uploaded successfully.', { toastId: '0' });
        //         } else {
        //             toast.error('File upload failed.', { toastId: '0' });
        //         }
        //     })
        //     .catch((error) => {
        //         console.error('Error uploading file:', error);
        //         toast.error('Error uploading file. Please try again.', { toastId: '0' });
        //     })
        //     .finally(() => {
        //         console.log('Upload process finished.');
        //     });
    };

    const change_selected_panel = (panel: string) => {
        if (panel === 'Communication') {
            set_panels(grafana_data?.['Comms'])
            set_selected_panel(panel)
            return
        } else if (panel === 'Payload') {
            set_panels(grafana_data?.['PAYLOAD']?.['PayloadApp'])
            set_selected_panel(panel)
            return
        } else {
            set_panels(grafana_data?.[panel])
            set_selected_panel(panel)
            return
        }
    }

    const change_monitor_view_type = (view: string) => {
        set_monitor_view_type(view)
    }

    return {
        panels,
        loading,
        selected_panel,
        change_selected_panel,
        monitor_view_type,
        change_monitor_view_type,
        handleFileUpload,
        fileName,
    }
}

export default useMonitor