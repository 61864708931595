// @ts-nocheck
import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import {
    setPayloadSliderFalse,
} from '../../../../../store/MissionSlice';
const PayloadSlider = ({ payloadSliderData }) => {

    const dispatch = useDispatch();

    const atmosData = payloadSliderData?.atmosData ? payloadSliderData?.atmosData : payloadSliderData?.["atmos-data"]
    const userData = payloadSliderData?.userData ? payloadSliderData?.userData : payloadSliderData?.["user_data"]


    return (
        <div className="right-slider-box">
            <div className="slider-header">
                <h4>HW Device Details</h4>
            </div>
            <div className="slider-content">
                {/*<div className="common-card-box w-100" style={{display:'flex', position:"absolute", top: 100,}}>*/}
                <div className="common-card-box w-100">
                    <div className="common-card-header flex-column d-flex align-items-baseline">
                        {userData?.['payload_name']}
                    </div>
                    <div className="common-card-footer" style={{ color: 'rgba(255,255,255,0.7)' }}>
                        <div className="d-flex flex-column">
                            <span><span className="temp-label">Payload Type </span>: {atmosData?.['component_specific_attributes']['payloadType']}</span>
                            {atmosData?.['component_specific_attributes']['payloadSubType'] !== '' && <span><span className="temp-label">Payload SubType </span>: {atmosData?.['component_specific_attributes']['payloadSubType']}</span>}
                            {atmosData?.['component_specific_attributes']['payloadDetails'] !== '' && <span><span className="temp-label">Payload Details </span>: {atmosData?.['component_specific_attributes']['payloadDetails']}</span>}
                            <span><span className="temp-label">Size </span>: {atmosData?.['product-cookie']['Size'] + ' U'}</span>
                            <span><span className="temp-label">Mass </span>: {(atmosData?.['weight_in_gms'] > 999) ?
                                ((atmosData?.['weight_in_gms'] / 1000) + ' kg')
                                : (atmosData?.['weight_in_gms'] + ' gm')}</span>
                            <span><span className="temp-label">Peak Power </span>: {atmosData?.['max_power_consumption_in_watts'] + ' W'}</span>
                            {atmosData?.['product-cookie']?.['Supported Interface'] && <span><span className="temp-label">Suported Interfaces </span>: {atmosData?.['product-cookie']?.['Supported Interface']}</span>}
                        </div>
                        <div className="d-flex flex-column">
                            {atmosData?.['component_specific_attributes']['typeSpecificParams'] &&
                                Object.keys(atmosData?.['component_specific_attributes']['typeSpecificParams'])?.length > 0 &&
                                Object.keys(atmosData?.['component_specific_attributes']['typeSpecificParams']).map((k, i) => {

                                    return (
                                        <span className='d-flex ' key={i}><span className="temp-label">{k}</span>: <span className='d-flex flex-wrap flex-1 ms-1'>
                                            {k === 'GSD' ? (atmosData?.['component_specific_attributes']?.['typeSpecificParams']?.[k] + ' m') :
                                                k === 'Swath' ? (atmosData?.['component_specific_attributes']?.['typeSpecificParams']?.[k] + ' km') :
                                                    k === 'Frequency Bands' ? atmosData?.['component_specific_attributes']?.['typeSpecificParams']?.[k].map((band, index) => (
                                                        <span className='d-flex'>{band} {atmosData?.['component_specific_attributes']?.['typeSpecificParams']?.[k].length !== index + 1 && '|'}</span>
                                                    ))
                                                        : atmosData?.['component_specific_attributes']?.['typeSpecificParams']?.[k]}</span>
                                        </span>
                                    )
                                })}
                        </div>
                        <div className="d-flex flex-column">
                            {atmosData?.['component_specific_attributes']['additionalParams'] &&
                                Object.keys(atmosData?.['component_specific_attributes']['additionalParams'])?.length > 0 &&
                                Object.keys(atmosData?.['component_specific_attributes']['additionalParams']).map((k, i) => {
                                    return <span key={i} className='d-flex gap-1'>
                                        <span className="temp-label">{k}</span> : {
                                            atmosData?.['component_specific_attributes']['additionalParams'][k]
                                        }
                                    </span>
                                })}
                        </div>
                        <div className='d-flex'><span className="temp-label">Interface </span>: <span className='d-flex flex-wrap ms-1'>
                            {atmosData?.['component_specific_attributes']?.['interfaces'].map((item, i) => (
                                <span className='d-flex ps-1' key={i}>{item} {atmosData?.['component_specific_attributes']?.['interfaces']?.length !== i + 1 && ' | '}</span>
                            ))}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='slider-footer'>
                <Button
                    variant="primary"
                    type="button"
                    className="btn btn-primary pt-1 pb-1 pe-6 ps-6 me-3 fs-14"
                    onClick={() => {
                        dispatch(setPayloadSliderFalse())
                    }}
                >
                    Close
                </Button>
            </div>
        </div>
    )
}

export default PayloadSlider
