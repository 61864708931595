
import { createSlice, PayloadAction, nanoid } from '@reduxjs/toolkit';

export class usageStore {
    aopCalculateUsage: number = 0;
    totalPayloadAOPUsage: number = 0;
    totalEdgeAOPUsage: number = 0;
    maxSizeUsage = 0;
    maxWeightUsage = 0;
    maxPowerUsage = 0;
    busSizeUsage = 0;
    busWeightUsage = 0;
    busPowerUsage = 0;
    payloadSizeUsage = 0;
    payloadWeightUsage = 0;
    payloadPowerUsage = 0;
    edgePowerUsage = 0;
    edgeSizeUsage = 0;
    edgeWeightUsage = 0;
    payloadPerCent = {
        size: 0,
        weight: 0,
        power: 0,
    };
    edgePerCent = {
        size: 0,
        weight: 0,
        power: 0,
    };
    busPerCent = {
        size: 0,
        weight: 0,
        power: 0,
    };
}

export const usageSlice = createSlice({
    name: 'usageSlice',
    initialState: {
        aopCalculateUsage: 0,
        totalPayloadAOPUsage: 0,
        totalEdgeAOPUsage: 0,
        maxSizeUsage: 0,
        maxWeightUsage: 0,
        maxPowerUsage: 0,
        busSizeUsage: 0,
        busWeightUsage: 0,
        busPowerUsage: 0,
        payloadSizeUsage: 0,
        payloadWeightUsage: 0,
        payloadPowerUsage: 0,
        edgePowerUsage: 0,
        edgeSizeUsage: 0,
        edgeWeightUsage: 0,
        payloadPerCent: {
            size: 0,
            weight: 0,
            power: 0,
        },
        edgePerCent: {
            size: 0,
            weight: 0,
            power: 0,
        },
        busPerCent: {
            size: 0,
            weight: 0,
            power: 0,
        },
    },
    reducers: {
        setPayloadSizeUsage: (state, action) => {
            state.payloadSizeUsage = action.payload;
        },
        setTotalAopCalculateUsage: (state, action) => {
            state.aopCalculateUsage = action.payload;
        },
        setTotalPayloadAOPUsage: (state, action) => {
            state.totalPayloadAOPUsage = action.payload;
        },
        setTotalEdgeAOPUsage: (state, action) => {
            state.totalEdgeAOPUsage = action.payload;
        },
        setPayloadWeightUsage: (state, action) => {
            state.payloadWeightUsage = action.payload;
        },
        setPayloadPowerUsage: (state, action) => {
            state.payloadPowerUsage = action.payload;
        },
        setEdgePowerUsage: (state, action) => {
            state.edgePowerUsage = action.payload;
        },
        setEdgeSizeUsage: (state, action) => {
            state.edgeSizeUsage = action.payload;
        },
        setEdgeWeightUsage: (state, action) => {
            state.edgeWeightUsage = action.payload;
        },
        setPayloadUsagePercentage: (state, action) => {
            state.payloadPerCent.size = action.payload.size;
            state.payloadPerCent.weight = action.payload.weight;
            state.payloadPerCent.power = action.payload.power;
        },
        setEdgeUsagePercentage: (state, action) => {
            state.edgePerCent.size = action.payload.size;
            state.edgePerCent.weight = action.payload.weight;
            state.edgePerCent.power = action.payload.power;
        },
        setBusUsage: (state, action) => {
            state.busSizeUsage = action.payload.busSizeUsage;
            state.busWeightUsage = action.payload.busWeightUsage;
            state.busPowerUsage = action.payload.busPowerUsage;
        },
        clearBusUsage: (state) => {
            state.busSizeUsage = 0;
            state.busWeightUsage = 0;
            state.busPowerUsage = 0;
        },
        setBusPercentage: (state, action) => {
            state.busPerCent.size = action.payload.size;
            state.busPerCent.weight = action.payload.weight;
            state.busPerCent.power = action.payload.power;
        },
        setMaxUsage: (state, action) => {
            state.maxSizeUsage = action.payload.maxSizeUsage;
            state.maxWeightUsage = action.payload.maxWeightUsage;
            state.maxPowerUsage = action.payload.maxPowerUsage;
        },
    },
});

export const {
    setTotalAopCalculateUsage,
    setTotalPayloadAOPUsage,
    setTotalEdgeAOPUsage,
    setPayloadSizeUsage,
    setPayloadWeightUsage,
    setPayloadPowerUsage,
    setEdgePowerUsage,
    setEdgeSizeUsage,
    setEdgeWeightUsage,
    setPayloadUsagePercentage,
    setEdgeUsagePercentage,
    setBusUsage,
    clearBusUsage,
    setBusPercentage,
    setMaxUsage,
} = usageSlice.actions;

export default usageSlice.reducer;