//@ts-nocheck
import * as Cesium from "cesium";
import MissionService from "../../../../service/MissionService";
import CommandCenterService from "../../../../service/CommandCenterService";


const getEntityById = (viewer: Cesium.Viewer | null, dataSourceName: string, entityId: string) => {
    if (!viewer) return
    const dataSource = getDataSourceByName(viewer, dataSourceName)
    if (!dataSource) return
    const entity = dataSource.entities.getById(entityId);
    return entity
}

const request_render = (viewer: Cesium.Viewer | null) => {
    if (!viewer) return
    viewer.scene.requestRender();
    setTimeout(() => {
        viewer.scene.requestRender();
    }, 100);
}


function removeDataSourceByName(viewer: Cesium.Viewer | null, dataSourceName: string) {
    if (!viewer) return
    const dataSources = viewer.dataSources;
    if (!dataSources) return
    for (let i = 0; i < dataSources.length; i++) {
        const dataSource = dataSources.get(i);
        if (dataSource.name === dataSourceName) {
            dataSources.remove(dataSource);
            return
        }
    }
}

function getDataSourceByName(viewer: Cesium.Viewer | null, dataSourceName: string) {
    if (!viewer) return
    const dataSources = viewer.dataSources;
    if (!dataSources) return
    for (let i = 0; i < dataSources.length; i++) {
        const dataSource = dataSources.get(i);
        if (dataSource.name === dataSourceName) {
            return dataSource;
        }
    }
    return null;
}

const getOrbitAvailability = (viewer: Cesium.Viewer | null, datasourceName: string) => {
    if (!viewer) return
    const dataSource = getDataSourceByName(viewer, datasourceName);
    if (dataSource && dataSource.clock) {
        const start = dataSource.clock.startTime;
        const stop = dataSource.clock.stopTime;
        return `${start.toString()}/${stop.toString()}`
    }
}

const fetchOrbitData = async (scenario_id: string, mission_id: string) => {
    try {
        // const response = await MissionService.getOribtData(scenario_id, satellite_id, "scenario")
        // const data = response?.data
        // let satellites = (data?.satellite && data?.satellite?.length > 0) ? data?.satellite : []
        // return satellites
        const response = await CommandCenterService.getnewOrbitData(mission_id, scenario_id, "scenario")
        const data = response?.data
        // let satellites = (data?.satellite && data?.satellite?.length > 0) ? data?.satellite : []
        return data


    } catch (error) {
        return null
    }
};

// convert the position caoordinate to Cartographic Degrees
const getCartographicDegrees = (position: any) => {
    let cartographicDegrees: any[] = [];
    position.forEach((element: any[]) => {
        cartographicDegrees.push(element[0]);
        cartographicDegrees.push(element[1]);
        cartographicDegrees.push(0);
    });
    return cartographicDegrees;
}

// building AIO datasource
export const create_aoi_packet = (viewer: Cesium.Viewer | null, aois: any[], groundTargets: any[]) => {
    if (!viewer) return
    let aoiData: any[] = [];
    let groundTargetData: any[] = [];

    aois && aois?.forEach((aoi: any, index) => {
        const details = aoi?.groundTarget

        let aoiPacket = {
            "description": "area of interest - " + index,
            "id": aoi?.refId,
            "name": details?.name,
            "polygon": {
                "extrudedHeight": 0,
                "height": 0,
                "material": {
                    "solidColor": {
                        "color": {
                            "rgba": [204, 245, 78, 50]
                        }
                    }
                },
                "arcType": "RHUMB",
                "positions": {
                    "cartographicDegrees": getCartographicDegrees(details?.polygon)
                }
            }
        }
        aoiData.push(aoiPacket)
    })
    groundTargets && groundTargets?.forEach((groundTarget: any, index) => {
        const details = groundTarget?.groundTarget
        console.log(details);

        let gtPacket = {
            "billboard": {
                "eyeOffset": {
                    "cartesian": [
                        0,
                        0,
                        0
                    ]
                },
                "horizontalOrigin": "CENTER",
                "image": '/assets/gs_target.svg',
                "pixelOffset": {
                    "cartesian2": [
                        0,
                        0
                    ]
                },
                "scale": 1,
                "show": true,
                "verticalOrigin": "CENTER"
            },
            "description": "Ground Target - " + index,
            "id": groundTarget?.refId,
            "label": {
                "fillColor": {
                    "rgba": [
                        255,
                        255,
                        255,
                        255
                    ]
                },
                "font": "montserrat",
                "horizontalOrigin": "LEFT",
                "outlineColor": {
                    "rgba": [
                        0,
                        0,
                        0,
                        255
                    ]
                },
                "outlineWidth": 2,
                "pixelOffset": {
                    "cartesian2": [
                        12,
                        0
                    ]
                },
                "show": true,
                "style": "FILL_AND_OUTLINE",
                "verticalOrigin": "CENTER"
            },
            "name": details?.['name'],
            "position": {
                "cartographicDegrees": details?.position?.length > 0 ?
                    [
                        details?.position?.[0],
                        details?.position?.[1],
                        details?.position?.[2]
                    ]
                    : [
                        details?.coordinates?.long,
                        details?.coordinates?.lat,
                        0
                    ]
            }
        }
        groundTargetData.push(gtPacket)
    })
    removeDataSourceByName(viewer, "AOI_Object")
    removeDataSourceByName(viewer, "GT_Object")
    const aoi_dataSource = new Cesium.CzmlDataSource();
    const gt_dataSource = new Cesium.CzmlDataSource();

    if (aoiData.length) {
        viewer && viewer.dataSources.add(aoi_dataSource.load([
            {
                "clock": {
                    "multiplier": 0,
                    "range": "CLAMPED",
                    "step": "SYSTEM_CLOCK_MULTIPLIER"
                },
                "id": "document",
                "name": "AOI_Object",
                "version": "1.0"
            },
            ...aoiData
        ]))
    }
    if (groundTargetData.length) {
        viewer && viewer.dataSources.add(gt_dataSource.load([
            {
                "clock": {
                    "multiplier": 0,
                    "range": "CLAMPED",
                    "step": "SYSTEM_CLOCK_MULTIPLIER"
                },
                "id": "document",
                "name": "GT_Object",
                "version": "1.0"
            },
            ...groundTargetData
        ]))
    }
    request_render(viewer);
}

export const create_satellite_packet = async (
    viewer: Cesium.Viewer | null,
    scenario_id: any,
    mission_id: any,
    selected_schedule: any
) => {
    if (!viewer) return;
    const satellites = await fetchOrbitData(scenario_id, mission_id);
    const satGroup = satellites?.satellites?.flatMap(singleSat => singleSat.visualizationData.satellites || []);
    if (satGroup) {
        let satelliteData: any[] = [];

        satGroup.forEach(satellite => {
            const isSelectedSatellite = satellite?.satId === selected_schedule?.value?.satId;

            const packet = {
                availability: satellite?.availability,
                billboard: {
                    eyeOffset: { cartesian: [0, 0, 0] },
                    horizontalOrigin: "CENTER",
                    image: '/assets/selectedSatellite.png',
                    pixelOffset: { cartesian2: [0, 0] },
                    scale: 0.75,
                    show: true,
                    verticalOrigin: "CENTER"
                },
                description: "Attached Object to satellite",
                id: satellite?.satId,
                path: {
                    leadTime: [{ number: 5000 }],
                    width: 1,
                    material: {
                        solidColor: {
                            color: {
                                rgba: isSelectedSatellite
                                    ? [204, 245, 78, 255] // Red for the selected satellite
                                    : (satellite?.isAdditionalObject
                                        ? [142, 125, 238, 100] // Semi-transparent purple for additional objects
                                        : (satellite?.simulatedOrbit
                                            ? [138, 138, 138, 250] // Semi-transparent gray for simulated orbits
                                            : [138, 138, 138, 100]) // Default semi-transparent gray
                                    )
                            }
                        }
                    },
                    resolution: 10,
                    show: [{ boolean: true, interval: satellite?.availability }],
                    trailTime: [{ number: 5000 }]
                },
                position: {
                    cartographicDegrees: satellite?.satOrbitPoint,
                    epoch: satellite?.availability?.split('/')[0]
                }
            };

            satelliteData.push(packet);
        });

        removeDataSourceByName(viewer, "Orbit_Object");

        const dataSource = new Cesium.CzmlDataSource();
        viewer.dataSources.add(dataSource.load([
            {
                clock: {
                    interval: satGroup[0]?.availability,
                    multiplier: 0,
                    range: "CLAMPED",
                    step: "SYSTEM_CLOCK_MULTIPLIER"
                },
                id: "document",
                name: "Orbit_Object",
                version: "1.0"
            },
            ...satelliteData
        ]));

        request_render(viewer);
    }
};


export const create_gs_packets = (viewer: Cesium.Viewer | null, ground_stations: any, selected_ground_stations: any) => {
    if (!viewer) return

    let gsPacketList: any[] = [];
    ground_stations.forEach((station: any) => {
        const gsId = station?.groundStationId
        const packet = {
            "billboard": {
                "horizontalOrigin": Cesium.HorizontalOrigin.CENTER,
                "verticalOrigin": Cesium.VerticalOrigin.CENTER,
                "image": selected_ground_stations.includes(gsId)
                    ? '/assets/select_gs.svg'
                    : '/assets/un_select_gs.svg',
                "disableDepthTestDistance": Number.POSITIVE_INFINITY, // Always visible
                "show": true
            },
            "description": "Ground Station - " + gsId,
            "label": {
                "fillColor": Cesium.Color.fromBytes(255, 255, 255, 255),
                "font": "montserrat",
                "horizontalOrigin": "LEFT",
                "outlineColor": Cesium.Color.fromBytes(0, 0, 0, 255),
                "outlineWidth": 2,
                "pixelOffset": {
                    "cartesian2": [
                        15,
                        0
                    ]
                },
                "show": false,
                "style": "FILL_AND_OUTLINE",
                "verticalOrigin": "CENTER",
                "text": station?.groundStationName ? station?.groundStationName : station?.name,
            },
            "name": station?.groundStationName ? station?.groundStationName : station?.name,
            "position": {
                "cartographicDegrees": [
                    station?.properties?.longitude,
                    station?.properties?.latitude,
                    Number(station?.properties?.altitude) || 0,
                ]
            },
            "id": gsId,
        };
        gsPacketList.push(packet)
    })
    removeDataSourceByName(viewer, "GS_Object")
    const dataSource = new Cesium.CzmlDataSource();
    viewer && viewer.dataSources.add(dataSource.load([
        {
            "clock": {
                "multiplier": 0,
                "range": "CLAMPED",
                "step": "SYSTEM_CLOCK_MULTIPLIER"
            },
            "id": "document",
            "name": "GS_Object",
            "version": "1.0"
        },
        ...gsPacketList
    ]))
    request_render(viewer);
}

export const selected_aoi_packet = (viewer: Cesium.Viewer | null, task: any, scenario_id?: any, mission_id?: any) => {
    if (!viewer) return
    let aois: any[] = []
    let targets: any[] = []
    task?.taskDetails?.map((task: any) => {
        if (task?.groundTarget?.type === "Geofence") {
            aois.push(task)
        } else if (task?.groundTarget?.type === "Target Track") {
            targets.push(task)
        }
    })
    create_aoi_packet(viewer, aois, targets)
    create_satellite_packet(viewer, scenario_id, mission_id)
}

export const select_unselect_gs = (viewer: Cesium.Viewer | null, ground_station: string, state: string) => {
    if (!viewer) return
    const entity: any = getEntityById(viewer, "GS_Object", ground_station)
    if (entity) {
        if (state === 'checked') {
            entity.billboard.image = '/assets/select_gs.svg';
        } else {
            entity.billboard.image = '/assets/un_select_gs.svg';
        }
        request_render(viewer);
    }
}

export const focus_gs = (viewer: Cesium.Viewer | null, station: string, selected_ground_stations: string[]) => {
    if (!viewer) return
    const datasource: any = getDataSourceByName(viewer, "GS_Object")
    if (datasource.entities && datasource.entities.values) {
        for (const entity of datasource.entities.values) {
            if (entity && entity.billboard) {
                if (entity.id === station) {
                    var position = entity.position.getValue(viewer.clock.currentTime);
                    var cartographic = Cesium.Cartographic.fromCartesian(position);
                    entity.billboard.image = '/assets/selectedHowerGs.png';
                    viewer.camera.flyTo({
                        destination: Cesium.Cartesian3.fromRadians(
                            cartographic.longitude,
                            cartographic.latitude,
                            10000000
                        ),
                        duration: 3
                    });
                } else {
                    entity.billboard.image = selected_ground_stations.includes(entity.id) ? '/assets/select_gs.svg' : '/assets/un_select_gs.svg';
                }
            }
        }
    }
}


export const removed_focus_gs = (viewer: Cesium.Viewer | null, selected_ground_stations: string[]) => {
    if (!viewer) return
    const datasource = getDataSourceByName(viewer, "GS_Object")
    if (datasource && datasource.entities && datasource.entities.values) {
        for (const entity of datasource.entities.values) {
            if (entity && entity.billboard) {
                entity.billboard.image = new Cesium.ConstantProperty(selected_ground_stations.includes(entity.id) ? '/assets/select_gs.svg' : '/assets/un_select_gs.svg');
                const element = document.getElementById(entity.id);
                if (element) {
                    element.style.color = '';
                }
            }
        }
    }
    viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromRadians(
            0,
            0,
            35500000
        ),
        duration: 3
    });
}

export const remove_additional_satellite_packet = async (viewer: Cesium.Viewer | null, satId: any,) => {
    if (!viewer) return
    const datasource = getDataSourceByName(viewer, 'Orbit_Object')
    if (!datasource) return
    const entity = getEntityById(viewer, 'Orbit_Object', satId);
    if (!entity) return;
    datasource.entities.remove(entity);
    request_render(viewer);
}