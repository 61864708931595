
import StepHeader from './StepHeader'
import { Form } from 'react-bootstrap'
import CustomSelect from '../../../../../CommonComponents/CustomSelect'
import useUrlParams from '../../../hooks/useUrlParams'
import useScenario from '../../../hooks/useScenario'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import { getYears, orbit_field_names, validate_orbit_details, validate_scenario_time } from '../../../utils'
import { CloseSvg, EditSvg, StepCompletedSvg } from '../../../svgs/Svgs'
import { useMemo, useState } from 'react'
import '../Creation.scss'
import { toast } from 'react-toastify'
import CustomToolTip from '../../../../../CommonComponents/CustomToolTip'
import { SatelliteOrbitType, SelectedSatelliteType } from '../../../types'

dayjs.extend(utc)

const Satellite = () => {

    const { scenario_step, navigate_scenario_step } = useUrlParams()
    const { loading, satellite, all_satellites, scenario_response, scenario_start_time, set_form_data, edit_satellite, set_edit_satellite } = useScenario()
    const [orbit_data, set_orbit_data] = useState<SatelliteOrbitType | null>(null)

    const active = useMemo(() => scenario_step === 'satellite' ? 'active' : '', [scenario_step])
    const completed = useMemo(() => scenario_response && scenario_response?.['satelliteGroups']?.length > 0 ? true : false, [scenario_response])
    const disabled = useMemo(() => (loading || (!completed && !active)) ? 'disabled' : '', [loading, completed, active])
    useMemo(() => satellite && set_orbit_data(satellite?.value?.orbit_data), [satellite])
    const show_sat_details = useMemo(() => orbit_data ? true : false, [orbit_data])

    const set_scenario_time = (value: any) => {
        set_form_data('scenario_start_time', dayjs.utc(value).unix())
    }

    const set_orbit_details = (field: string, value: string | number) => {
        const new_orbit_details: SatelliteOrbitType = {
            altitude: orbit_data ? orbit_data['altitude'] : '',
            eccentricity: orbit_data ? orbit_data['eccentricity'] : '',
            inclination: orbit_data ? orbit_data['inclination'] : '',
            RAAN: orbit_data ? orbit_data['RAAN'] : '',
            AP: orbit_data ? orbit_data['AP'] : '',
            TA: orbit_data ? orbit_data['TA'] : '',
        };
        new_orbit_details[field] = value;
        set_orbit_data(new_orbit_details)
    }

    const update_satellite_orbit_details = () => {
        if (!orbit_data || !satellite) return toast.error(!orbit_data ? 'Please enter group configurations.' : 'Please select a satellite.', { toastId: 'satellite-error' })
        else if (!validate_scenario_time(scenario_start_time)) return
        else if (!validate_orbit_details(orbit_data)) return
        else {
            const satellite_data: SelectedSatelliteType = {
                ...satellite,
                value: {
                    ...satellite?.['value'],
                    orbit_data: orbit_data as SelectedSatelliteType['value']['orbit_data'],
                }
            }
            set_form_data('satellite', satellite_data)
            set_edit_satellite(false)
        }
    }

    return (
        <section id='satellite' className={`dropdown__container ${active} ${disabled} `}>
            <StepHeader
                step='Satellites'
                completed={completed}
                onClick={() => { navigate_scenario_step('satellite') }}
            />
            <div className='dropdown__container__body'>
                <div className='w-100 d-flex flex-column gap-3'>
                    <CustomSelect
                        options={all_satellites}
                        placeholder='Select Satellite or Satellite Group'
                        value={satellite}
                        onChange={(e: { label: string, value: any }) => {
                            const selected_satellite = {
                                label: e.label,
                                value: e.value,
                            }
                            set_form_data('satellite', selected_satellite)
                        }}
                    />
                    {show_sat_details &&
                        <div className='w-100 d-flex flex-column gap-2'>
                            <div className='d-flex align-items-center justify-content-between'>
                                <span className='opacity-60 fs-14'>Epoch</span>
                                <div className='d-flex align-items-center border rounded-1 overflow-hidden w-60 p-0'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                        <DateTimePicker
                                            sx={{
                                                borderRadius: '0px',
                                                background: 'rgba(10, 10, 10, 0.65)!important',
                                                '.MuiFormControl-root': {
                                                    paddingRight: '0px',
                                                },
                                                '.MuiInputBase-input': {
                                                    fontSize: '14px !important',
                                                },
                                                '.MuiOutlinedInput-input': {
                                                    fontSize: '14px !important',
                                                },
                                                '.MuiOutlinedInput-root': {
                                                    height: '30px !important',
                                                    paddingRight: '0px'
                                                },
                                                '.MuiInputAdornment-root': {
                                                    display: 'none',
                                                },
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    borderRadius: '0px',
                                                    border: 'none',
                                                    borderRight: '0.5px solid rgba(240,240,240,0.30) !important',
                                                }
                                            }}
                                            className="rounded-3"
                                            ampm={false} format='YYYY-MM-DD HH:mm:ss'
                                            shouldDisableYear={(date) => {
                                                const current_year = dayjs(date).year();
                                                const yearList = getYears(2002, 2037);
                                                return !yearList.includes(current_year);
                                            }}
                                            views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                                            value={dayjs.utc(scenario_start_time * 1000)} onChange={set_scenario_time} />
                                    </LocalizationProvider>
                                    <span className='opacity-75 fs-12 px-2'>UTC</span>
                                </div>
                            </div>
                            <div className='d-flex flex-column gap-2'>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <span className='opacity-70 fs-14'>ORBITAL CONFIGURATION</span>
                                    {!edit_satellite &&
                                        <CustomToolTip title='Edit' placement='top-end'>
                                            <button className='header__button'
                                                onClick={() => {
                                                    set_edit_satellite(true);
                                                }}>
                                                <EditSvg />
                                            </button>
                                        </CustomToolTip>
                                    }
                                    {edit_satellite &&
                                        <div className='d-flex align-items-center gap-1'>
                                            <CustomToolTip title='Save' placement='top'>
                                                <button className='header__button' onClick={() => {
                                                    update_satellite_orbit_details()
                                                }}>
                                                    <StepCompletedSvg color={'#F0F0F0'} />
                                                </button>
                                            </CustomToolTip>
                                            <CustomToolTip title='Close' placement='top-end'>
                                                <button className='header__button' onClick={() => {
                                                    set_edit_satellite(false)
                                                }}>
                                                    <CloseSvg />
                                                </button>
                                            </CustomToolTip>
                                        </div>
                                    }
                                </div>
                                <div className='border rounded-1 d-flex flex-column gap-2 py-2 px-3'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <span className='opacity-60 fs-12'>Altitude</span>
                                        <div className={`orbit__text__input__container w-40 ${!edit_satellite && 'view__only'}`}>
                                            <Form.Control
                                                value={orbit_data ? orbit_data.altitude : ''}
                                                min={orbit_field_names['altitude'].min}
                                                max={orbit_field_names['altitude'].max}
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 13 || e.keyCode === 69) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onChange={(event) => {
                                                    let value: string | number = event.target.value !== '' ? Number(event.target.value) : '';
                                                    if (value as number <= 750 && !isNaN(value as number)) {
                                                        if (event.target.value?.length <= 8) {
                                                            set_orbit_details('altitude', value);
                                                        }
                                                    }
                                                }}
                                                className="orbit__text__input"
                                                type="number"
                                                step='any'
                                                placeholder={''}
                                            />
                                            <div className='parameter__section ps-1'>km </div>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <span className='opacity-60 fs-12'>Eccentricity (e)</span>
                                        <div className={`orbit__text__input__container w-40 ${!edit_satellite && 'view__only'}`}>
                                            <Form.Control
                                                value={orbit_data ? orbit_data.eccentricity : ''}
                                                min={orbit_field_names['eccentricity'].min}
                                                max={orbit_field_names['eccentricity'].max}
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 13 || e.keyCode === 69) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onChange={(event) => {
                                                    let value: string | number = event.target.value !== '' ? Number(event.target.value) : '';
                                                    if (value as number >= 0 && value as number <= 0.05 && !isNaN(value as number)) {
                                                        if (event.target.value?.length <= 8) {
                                                            set_orbit_details('eccentricity', value);
                                                        }
                                                    } else {
                                                        toast.error("Eccentricity must be between 0 and 0.05", { toastId: '0' })
                                                    }
                                                }}
                                                className="orbit__text__input border-0 w-100"
                                                type="number"
                                                step='any'
                                                placeholder={''}
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <span className='opacity-60 fs-12'>Inclination (i)</span>
                                        <div className={`orbit__text__input__container w-40 ${!edit_satellite && 'view__only'}`}>
                                            <Form.Control
                                                value={orbit_data ? orbit_data.inclination : ''}
                                                min={orbit_field_names['inclination'].min}
                                                max={orbit_field_names['inclination'].max}
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 13 || e.keyCode === 69) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onChange={(event) => {
                                                    let value: string | number = event.target.value !== '' ? Number(event.target.value) : '';
                                                    if (value as number >= 0 && value as number <= 180 && !isNaN(value as number)) {
                                                        if (event.target.value?.length <= 8) {
                                                            set_orbit_details('inclination', value)
                                                        }
                                                    } else {
                                                        toast.error("Inclination must be between 0 and 180", { toastId: '0' })
                                                    }
                                                }}
                                                className="orbit__text__input"
                                                type="number"
                                                step='any'
                                                placeholder={''}
                                            />
                                            <div className='parameter__section'><sup>o </sup></div>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <span className='opacity-60 fs-12'>RAAN (Ω)</span>
                                        <div className={`orbit__text__input__container w-40 ${!edit_satellite && 'view__only'}`}>
                                            <Form.Control
                                                value={orbit_data ? orbit_data.RAAN : ''}
                                                min={orbit_field_names['RAAN'].min}
                                                max={orbit_field_names['RAAN'].max}
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 13 || e.keyCode === 69) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onChange={(event) => {
                                                    let value: string | number = event.target.value !== '' ? Number(event.target.value) : '';
                                                    if (value as number >= 0 && value as number <= 360 && !isNaN(value as number)) {
                                                        if (event.target.value?.length <= 8) {
                                                            set_orbit_details('RAAN', value)
                                                        }
                                                    } else {
                                                        toast.error("Longitude of Ascending Node must be between 0 and 360", { toastId: '0' })
                                                    }
                                                }}
                                                className="orbit__text__input"
                                                type="number"
                                                step='any'
                                                placeholder={''}
                                            />
                                            <div className='parameter__section'><sup>o </sup></div>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <span className='opacity-60 fs-12'>Argument of Perigee (ω)</span>
                                        <div className={`orbit__text__input__container w-40 ${!edit_satellite && 'view__only'}`}>
                                            <Form.Control
                                                value={orbit_data ? orbit_data.AP : ''}
                                                min={orbit_field_names['AP'].min}
                                                max={orbit_field_names['AP'].max}
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 13 || e.keyCode === 69) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onChange={(event) => {
                                                    let value: string | number = event.target.value !== '' ? Number(event.target.value) : '';
                                                    if (value as number >= 0 && value as number <= 360 && !isNaN(value as number)) {
                                                        if (event.target.value?.length <= 8) {
                                                            set_orbit_details('AP', value)
                                                        }
                                                    } else {
                                                        toast.error("Argument of periapsis must be between 0 and 360", { toastId: '0' })
                                                    }
                                                }}
                                                className="orbit__text__input"
                                                type="number"
                                                step='any'
                                                placeholder={''}
                                            />
                                            <div className='parameter__section'><sup>o </sup></div>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <span className='opacity-60 fs-12'>True Anomaly (&#957;)</span>
                                        <div className={`orbit__text__input__container w-40 ${!edit_satellite && 'view__only'}`}>
                                            <Form.Control
                                                value={orbit_data ? orbit_data.TA : ''}
                                                min={orbit_field_names['TA'].min}
                                                max={orbit_field_names['TA'].max}
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 13 || e.keyCode === 69) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onChange={(event) => {
                                                    let value: string | number = event.target.value !== '' ? Number(event.target.value) : '';
                                                    if (value as number >= 0 && value as number <= 360 && !isNaN(value as number)) {
                                                        if (event.target.value?.length <= 8) {
                                                            set_orbit_details('TA', value)
                                                        }
                                                    } else {
                                                        toast.error("True Anomaly must be between 0 and 360", { toastId: '0' })
                                                    }
                                                }}
                                                className="orbit__text__input"
                                                type="number"
                                                step='any'
                                                placeholder={''}
                                            />
                                            <div className='parameter__section'><sup>o </sup></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex flex-column gap-2'>
                                <span className='opacity-70 fs-14'>PHASING CONFIGURATION</span>
                                <div className='border rounded-1 d-flex flex-column gap-2 px-3 py-1'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <span className='opacity-60 fs-12'>Plane Phasing (ΔΩ)</span>
                                        <div className={`orbit__text__input__container w-40 view__only`}>
                                            <Form.Control
                                                value={satellite?.value?.['planePhasing']}
                                                className={`orbit__text__input`}
                                            />
                                            <div className='parameter__section'><sup>o </sup></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </section>
    )
}

export default Satellite