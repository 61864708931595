import { ReactNode, createContext, useEffect, useState } from "react";
import { AllOnBoardSchedule, ScheduleData } from "../hooks/useOnBoardSchedule";
import { ContactStation } from "../hooks/useGroundContact";
import CommandCenterService from "../../../service/CommandCenterService";
import useScenario from "../hooks/useScenario";
import useUrlParams from "../hooks/useUrlParams";
import useSatellite from "../hooks/useSatellite";

interface ContactsContextType {
    selectedScheduleTask: {
        default_schedule: boolean;
        schedule_data: ScheduleData;
    };
    setSelectedScheduleTask: (data: {
        default_schedule: boolean;
        schedule_data: ScheduleData;
    }) => void;
    selectedContact: {
        contact_type: string;
        contact_data: ContactStation,
    } | null;
    setSelectedContact: (data: {
        contact_type: string;
        contact_data: ContactStation,
    } | null) => void;
    contacts: {
        previous_contact: null | ContactStation,
        ongoing_contact: null | ContactStation,
        upcoming_contact: null | ContactStation
        current_time: null | number,
    }
    setContacts: (data: {
        previous_contact: null | ContactStation,
        ongoing_contact: null | ContactStation,
        upcoming_contact: null | ContactStation
        current_time: null | number,
    }) => void;
    contactData: ContactStation[];
    scheduleData: ScheduleData[];
    timelineScheduleData: ScheduleData[];
    scheduleDetails: {
        start_time: number,
        name: string,
    };
    allOnBoardSchedule: AllOnBoardSchedule;
    selectedSchedule: null | string;
    select_schedule: (schedule: any) => void;
    generate_schedule: (schedule: any) => Promise<void>;
    delete_onboard_schedule: (schedule: any) => Promise<void>;
}

export const ContactsContext = createContext<ContactsContextType | undefined>(undefined);

interface ContactsProviderProps {
    children: ReactNode;
}
const ContactsProvider = ({ children }: ContactsProviderProps) => {
    const { scenario } = useScenario()
    const { selected_satellite } = useSatellite()
    const { scenario_id, dashboard, mission_id } = useUrlParams()
    const [selectedScheduleTask, setSelectedScheduleTask] = useState<{
        default_schedule: boolean;
        schedule_data: ScheduleData;
    }>({
        default_schedule: true,
        schedule_data: {} as ScheduleData,
    })

    const [selectedSchedule, setSelectedSchedule] = useState<null | any>(null)

    const [selectedContact, setSelectedContact] = useState<
        {
            contact_type: string;
            contact_data: ContactStation,
        } | null
    >(null)
    const [contacts, setContacts] = useState<{
        previous_contact: null | ContactStation,
        ongoing_contact: null | ContactStation,
        upcoming_contact: null | ContactStation
        current_time: null | number,
    }>({
        previous_contact: null,
        ongoing_contact: null,
        upcoming_contact: null,
        current_time: null,
    })

    const [contactData, setContactData] = useState<ContactStation[]>([])
    const [allOnBoardSchedule, setAllOnBoardSchedule] = useState<AllOnBoardSchedule>({} as AllOnBoardSchedule)
    const [scheduleData, setScheduleData] = useState<ScheduleData[]>([])
    const [timelineScheduleData, setTimelineScheduleData] = useState<ScheduleData[]>([])
    const [scheduleDetails, setScheduleDetails] = useState<{
        start_time: number,
        name: string,
    }>({
        start_time: 0,
        name: '',
    })

    const structure_schedule_date = (schedule: any) => {
        let updated_data: ScheduleData[] = schedule.map(task => {
            return {
                ...task,
                "properties": {
                    ...task?.['properties'],
                    "extraDetails": task?.properties?.['extraDetails'] ?? task?.properties?.['ExtraDetails'],
                    "freqBand": task?.properties?.['freqBand'] ?? task?.properties?.['FreqBand'],
                    "groundStationName": task?.properties?.['groundStationName'] ?? task?.properties?.['GroundStationName'],
                    "payloadName": task?.properties?.['payloadName'] ?? task?.properties?.['PayloadName'],
                    "sequenceName": task?.properties?.['sequenceName'] ?? task?.properties?.['SequenceName'],
                }
            }
        })
        return updated_data
    }

    const select_schedule = (schedule: any) => {
        const Data = schedule?.['ScheduledTasks']
        setScheduleDetails({
            start_time: new Date(schedule?.['StartTime']).getTime() / 1000,
            name: schedule?.['ScheduleName'],
        })
        const schedule_data = structure_schedule_date(Data)
        setScheduleData(schedule_data)
        setTimelineScheduleData(schedule_data)
        setSelectedSchedule(schedule?.['ScheduleId'])
    }

    const get_timeline_onboard_schedule_data = () => {
        CommandCenterService.getOnBoardScheduleData(selected_satellite?.value?.['simulationId']).then((res) => {
            if (res?.data) {
                const Data = res.data
                setScheduleData(Data)
                setTimelineScheduleData(Data)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const get_ongoing_schedule = () => {
        if (dashboard === 'operate') {
            CommandCenterService.getOngoingSchedule(scenario_id).then((res) => {
                if (res?.data) {
                    const Data = res?.data?.['ScheduledTasks']
                    setScheduleDetails({
                        start_time: new Date(res?.data?.['StartTime']).getTime() / 1000,
                        name: res?.data?.['ScheduleName'],
                    })
                    const schedule_data = structure_schedule_date(Data)
                    setScheduleData(schedule_data)
                    setTimelineScheduleData(schedule_data)
                    select_schedule(res?.data)
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

    const get_all_onboard_schedules = () => {
        if (dashboard === 'operate') {
            CommandCenterService.getAllOnBoardSchedules(scenario_id).then((res) => {
                if (res?.data) {
                    const Data = res?.data
                    setAllOnBoardSchedule(Data)
                    console.log(res?.data);

                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

    const get_onboard_schedule_data = () => {
        if (dashboard !== 'operate') {
            CommandCenterService.getSimulationStatus(selected_satellite?.value?.simulationId).then((res) => {
                if (res?.data) {
                    const Data = res?.data?.['taskList']
                    setScheduleDetails({
                        start_time: res?.data?.['simulation_start_epoch'],
                        name: res?.data?.['scheduleName'],
                    })
                    const schedule_data = structure_schedule_date(Data)
                    setScheduleData(schedule_data)
                    setTimelineScheduleData(schedule_data)
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

    const get_ground_contact_data = () => {
        CommandCenterService.getGroundContactData(mission_id, scenario_id).then((res) => {
            if (res?.data) {
                console.log("All Ground Contact Data -> ", res?.data)
                let selected_sat_id = selected_satellite?.value?.['satelliteId'];
                setContactData(res?.data?.[selected_sat_id as keyof typeof res.data])
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const get_gs_contact_details = () => {
        if (dashboard === 'operate') {
            CommandCenterService.getGroundContacts(scenario_id).then((res) => {
                if (res?.['data']?.['groundStations']) {
                    setContactData(res?.['data']?.['groundStations'])
                }
            }).catch(() => {
                console.error('Failed to get contact details.');
            })
        }
    }

    const generate_schedule = (payload: any): Promise<string | any> => {
        return new Promise((resolve, reject) => {
            CommandCenterService.generateOperateSchedule(payload)
                .then((response) => {
                    if (response) {
                        // Assuming these are async functions, we should wait for them to complete
                        return Promise.all([
                            get_all_onboard_schedules(),
                            get_ongoing_schedule()
                        ]).then(() => {
                            resolve('success');
                        });
                    } else {
                        resolve('No response received');
                    }
                })
                .catch((err) => {
                    console.error('Error generating schedule:', err);
                    reject(err?.response?.data || 'An error occurred');
                });
        });
    };
    const delete_onboard_schedule = (scheduleId: string): Promise<string | any> => {
        return new Promise((resolve, reject) => {
            CommandCenterService.deleteOnboardSchedules(scenario_id, scheduleId)
                .then((response) => {
                    if (response) {
                        // Assuming these are async functions, we should wait for them to complete
                        return Promise.all([
                            get_all_onboard_schedules(),
                            get_ongoing_schedule()
                        ]).then(() => {
                            resolve('success');
                        });
                    } else {
                        resolve('No response received');
                    }
                })
                .catch((err) => {
                    console.error('Error generating schedule:', err);
                    reject(err?.response?.data || 'An error occurred');
                });
        });
    };


    useEffect(() => {
        if (scenario_id) {
            get_gs_contact_details()
            get_ongoing_schedule()
            get_all_onboard_schedules()
        }
    }, [scenario_id])


    useEffect(() => {
        if (selected_satellite?.value?.['simulationId']) {
            get_timeline_onboard_schedule_data()
            get_onboard_schedule_data()
            get_ground_contact_data()
        }
    }, [selected_satellite])


    return (
        <ContactsContext.Provider
            value={{
                contacts,
                contactData,
                setContacts,
                scheduleData,
                scheduleDetails,
                timelineScheduleData,
                selectedContact,
                selectedScheduleTask,
                setSelectedScheduleTask,
                setSelectedContact,
                allOnBoardSchedule,
                selectedSchedule,
                select_schedule,
                generate_schedule,
                delete_onboard_schedule
            }}>
            {children}
        </ContactsContext.Provider>
    )
}

export default ContactsProvider