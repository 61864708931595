import dayjs from 'dayjs';
import React, { createContext, useEffect, useState } from 'react'
import utc from 'dayjs/plugin/utc';
import { get_all_task_plans, get_mission_details, get_station_ids, set_edited_scenario_data } from '../utils';
import useUrlParams from '../hooks/useUrlParams';
import ModalProvider from './ModalProvider';
import { toast } from 'react-toastify';
import { AdditionalOrbit, ScenarioResponseType, SelectedSatelliteType, SelectedTaskplanType } from '../types';
import SimulateService from '../../../../service/SimulateService';
import { create_gs_packets, selected_aoi_packet, create_satellite_packet } from '../visualization/cesium_utils';
import useViewer from '../hooks/useViewer';
import CommandCenterService from '../../../../service/CommandCenterService';
dayjs.extend(utc)



type ScenarioContextType = {
    prediction_data: any;
    scenario_name: string;
    imported_schedule: any;
    edit_satellite: boolean;
    scenario_duration: number
    duration: string | number
    ground_stations: string[];
    loading: boolean | string;
    all_ground_stations: any[];
    scenario_start_time: number;
    simulation_start_time: number;
    additional_orbits: AdditionalOrbit[];
    prediction_loading: boolean | string;
    task_plan: SelectedTaskplanType | null;
    all_task_plans: SelectedTaskplanType[];
    all_satellites: SelectedSatelliteType[];
    satellite: SelectedSatelliteType | null;
    set_edit_satellite: (value: boolean) => void;
    scenario_response: ScenarioResponseType | null;
    additional_orbit_details: AdditionalOrbit | null;
    set_loading: (loading: boolean | string) => void;
    set_form_data: (name: string, data: any) => void;
    set_prediction_loading: (loading: boolean) => void;
    schedule_data: { label: string, value: any[] }[] | null;
    set_response_data: (name: string, data: any) => void;
    selected_schedule: { label: string, value: any[] } | null;
}

export const ScenarioContext = createContext<ScenarioContextType | undefined>(undefined)

const ScenarioProvider = ({ children }: { children: React.ReactNode }) => {

    const { viewer } = useViewer()
    const { mission_id, scenario_id, scenario_step, navigate_scenario_step } = useUrlParams()

    const [scenario_name, set_scenario_name] = useState<string>('')
    const [duration, set_duration] = useState<string | number>(180)
    const [loading, set_loading] = useState<boolean | string>(false)
    const [prediction_data, set_prediction_data] = useState<any>(null)
    const [ground_stations, set_ground_stations] = useState<string[]>([])
    const [imported_schedule, set_imported_schedule] = useState<any>(null)
    const [scenario_duration, set_scenario_duration] = useState<number>(1440)
    const [all_ground_stations, set_all_ground_stations] = useState<any[]>([])
    const [task_plan, set_task_plan] = useState<SelectedTaskplanType | null>(null)
    const [edit_satellite, set_edit_satellite] = useState<boolean>(false)
    const [satellite, set_satellite] = useState<SelectedSatelliteType | null>(null)
    const [all_task_plans, set_all_task_plans] = useState<SelectedTaskplanType[]>([])
    const [all_satellites, set_all_satellites] = useState<SelectedSatelliteType[]>([])
    const [additional_orbits, set_additional_orbits] = useState<AdditionalOrbit[]>([])
    const [prediction_loading, set_prediction_loading] = useState<boolean | string>(false)
    const [scenario_response, set_scenario_response] = useState<ScenarioResponseType | null>(null)
    const [schedule_data, set_schedule_data] = useState<{ label: string, value: any[] }[] | null>(null)
    const [selected_schedule, set_selected_schedule] = useState<{ label: string, value: any[] } | null>(null)
    const [additional_orbit_details, set_additional_orbit_details] = useState<AdditionalOrbit | null>(null)
    const [scenario_start_time, set_scenario_start_time] = useState<number>(dayjs.utc(new Date()).unix())
    const [simulation_start_time, set_simulation_start_time] = useState<number>(dayjs.utc(new Date()).unix())

    const set_form_data = (name: string, data: any) => {
        if (scenario_step === 'deploy') {
            navigate_scenario_step('schedule')
        }
        switch (name) {
            case 'name':
                set_scenario_name(data)
                break;
            case 'satellite':
                set_satellite(data)
                get_task_plans(data.value.groupId)
                break;
            case 'additional_orbits_details':
                set_additional_orbit_details(data)
                break;
            case 'additional_orbits':
                set_additional_orbits(data)
                break;
            case 'duration':
                if (Number(data) <= scenario_duration && Number(data) >= 0) {
                    let simulationDoration = Math.abs(data)
                    set_duration(data !== '' ? simulationDoration : '')
                } else {
                    toast.error(`Duration should be between 10 and 1440 minutes`, { toastId: 'o' })
                }
                break;
            case 'task_plan':
                set_task_plan(data)
                break;
            case 'ground_stations':
                set_ground_stations(data)
                break;
            case 'scenario_start_time':
                set_scenario_start_time(data)
                set_simulation_start_time(data)
                break;
            case 'simulation_start_time':
                set_simulation_start_time(data)
                break;
            case 'import_schedule':
                set_imported_schedule(data)
                break;
            default:
                break;
        }
    }

    const set_response_data = (name: string, data: any) => {
        switch (name) {
            case 'scenario_response':
                set_scenario_response(data)
                break;
            case 'schedule_data':
                set_schedule_data(data)
                break;
            case 'selected_schedule':
                set_selected_schedule(data)
                break;
            case 'prediction_data':
                set_prediction_data(data)
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (!mission_id || !viewer) return
        SimulateService.getSatelliteGroups(mission_id)?.then((res) => {
            if (res.data) {
                let allSatellites: SelectedSatelliteType[] = [];
                res?.["data"]?.forEach((group: any) => {
                    allSatellites.push({
                        label: group?.["name"],
                        value: {
                            groupId: group?.['uniqueId'],
                            groupType: group?.['groupType'],
                            groupName: group?.["name"],
                            satCount: group?.["satCount"],
                            planePhasing: group?.['interPlanePhasing'],
                            orbit_data: {
                                altitude: group?.['referenceOrbitParameters']?.['altitude'],
                                inclination: group?.['referenceOrbitParameters']?.['inclination'],
                                eccentricity: group?.['referenceOrbitParameters']?.['eccentricity'],
                                RAAN: group?.['referenceOrbitParameters']?.['RAAN'],
                                AP: group?.['referenceOrbitParameters']?.['AP'],
                                TA: group?.['referenceOrbitParameters']?.['TA'],
                            },
                        }
                    });
                })
                set_all_satellites(allSatellites)
            }
        }).catch((err) => {
            console.error(err);
        })
        get_mission_details(mission_id)
            .then(stations => {
                const stations_ides = get_station_ids(stations)
                set_ground_stations(stations_ides)
                set_all_ground_stations(stations);
                if (!scenario_id) {
                    create_gs_packets(viewer, stations, stations_ides)
                }
            })
            .catch(error => {
                console.error("Error:", error);
            });
    }, [mission_id, viewer])

    const get_task_plans = async (groupId: string, taskPlanId?: string) => {
        if (!groupId) return
        try {
            const all_task_plans = await get_all_task_plans(groupId)
            if (all_task_plans) {
                set_all_task_plans(all_task_plans)
                if (taskPlanId) {
                    const selected_task_plan = all_task_plans.find((task_plan: any) => task_plan.value.taskPlanId === taskPlanId)
                    if (selected_task_plan) {
                        set_task_plan(selected_task_plan)
                        selected_aoi_packet(viewer, selected_task_plan.value)
                    }
                }
            }
        } catch (error) {
            toast.error("Failed to fetch task plans.", { toastId: "task_plan_error" })
        }

    }



    useEffect(() => {
        if (all_satellites && all_ground_stations?.length && ground_stations?.length && scenario_id && !scenario_response) {
            SimulateService.getScenario(mission_id, scenario_id).then((response) => {
                if (response?.data) {
                    const scenario: ScenarioResponseType = response?.data
                    set_scenario_response(scenario)
                    set_scenario_name(scenario?.scenarioName)

                    const selected_satellite = set_edited_scenario_data('satellite', scenario)
                    const scenario_time_data = set_edited_scenario_data('scenario_time', scenario)
                    const scenario_duration_data = set_edited_scenario_data('scenario_duration', scenario)
                    const simulation_time_data = set_edited_scenario_data('simulation_time', scenario)
                    const simulation_duration_data = set_edited_scenario_data('simulation_duration', scenario)
                    const ground_stations_data = set_edited_scenario_data('ground_station', scenario)

                    set_satellite(selected_satellite ? selected_satellite as SelectedSatelliteType : satellite)
                    set_scenario_duration(scenario_duration_data ? scenario_duration_data as number : scenario_duration)
                    set_scenario_start_time(scenario_time_data ? scenario_time_data as number : scenario_start_time)
                    set_simulation_start_time(simulation_time_data ? simulation_time_data as number : simulation_start_time)
                    set_duration(simulation_duration_data ? simulation_duration_data as number : duration)
                    set_ground_stations(ground_stations_data ? ground_stations_data as string[] : ground_stations)
                    if (scenario?.satelliteGroups?.length || satellite) {
                        const group_Id = scenario?.satelliteGroups[0]?.satelliteGroupId || satellite?.value.groupId || ''
                        get_task_plans(group_Id, scenario.taskPlanId)
                    }
                    create_gs_packets(viewer, all_ground_stations, ground_stations_data ? scenario?.['groundStations'] : ground_stations)
                    create_satellite_packet(viewer, scenario_id, mission_id, selected_schedule)
                }
            }).catch(() => {
                console.log("Error in getting scenario");
            })
        }
    }, [scenario_id, mission_id, all_satellites, all_ground_stations, ground_stations, scenario_response])

    useEffect(() => {
        create_satellite_packet(viewer, scenario_id, mission_id, selected_schedule)
    }, [selected_schedule])


    return (
        <ScenarioContext.Provider value={{
            loading,
            duration,
            satellite,
            task_plan,
            set_loading,
            scenario_name,
            set_form_data,
            schedule_data,
            edit_satellite,
            all_task_plans,
            all_satellites,
            prediction_data,
            ground_stations,
            scenario_duration,
            additional_orbits,
            imported_schedule,
            set_response_data,
            selected_schedule,
            scenario_response,
            set_edit_satellite,
            prediction_loading,
            all_ground_stations,
            scenario_start_time,
            simulation_start_time,
            set_prediction_loading,
            additional_orbit_details,
        }}>
            <ModalProvider>
                {children}
            </ModalProvider>
        </ScenarioContext.Provider>
    )
}

export default ScenarioProvider